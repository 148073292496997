.app-main {
  grid-area: #{$lte-prefix}app-main;
  max-width: 100vw;
  padding-bottom: 1rem;
  @include transition($lte-transition-speed $lte-transition-fn);

  .app-content-header {
    padding: 1rem $lte-content-padding-x;

    .breadcrumb {
      padding: 0;
      margin-bottom: 0;
      line-height: 2.5rem;
    }
  }

  .app-content {
    padding: $lte-content-padding-y $lte-content-padding-x;
  }
}
