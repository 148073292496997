//
// Callouts
//

.callout {
  --#{$prefix}link-color-rgb: var(--#{$lte-prefix}callout-link);
  --#{$prefix}code-color: var(--#{$lte-prefix}callout-code-color);

  padding: 1.25rem;
  color: var(--#{$lte-prefix}callout-color, inherit);
  background-color: var(--#{$lte-prefix}callout-bg, var(--bs-gray-100));
  border-left: .25rem solid var(--#{$lte-prefix}callout-border, var(--bs-gray-300));

  h4 {
    margin-bottom: .25rem;
  }

  > :last-child {
    margin-bottom: 0;
  }

  + .callout {
    margin-top: -.25rem;
  }
}

// Variations
@each $name, $color in $theme-colors {
  .callout-#{$name} {
    --#{$lte-prefix}callout-color: var(--#{$prefix}#{$name}-text-emphasis);
    --#{$lte-prefix}callout-bg: var(--#{$prefix}#{$name}-bg-subtle);
    --#{$lte-prefix}callout-border: var(--#{$prefix}#{$name}-border-subtle);
  }
}
