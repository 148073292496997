//
// Component: Direct Chat
//

.direct-chat {
  .card-body {
    position: relative;
    padding: 0;
    overflow-x: hidden;
  }

  &.chat-pane-open {
    .direct-chat-contacts {
      transform: translate(0, 0);
    }
  }


  &.timestamp-light {
    .direct-chat-timestamp {
      color: rgba(var(--#{$prefix}body-color-rgb), .65);
    }
  }

  &.timestamp-dark {
    .direct-chat-timestamp {
      color: rgba(var(--#{$prefix}body-color-rgb), .9);
    }
  }
}

.direct-chat-messages {
  height: 250px;
  padding: 10px;
  overflow: auto;
  transform: translate(0, 0);
}

.direct-chat-msg,
.direct-chat-text {
  display: block;
}

.direct-chat-msg {
  @include clearfix();
  margin-bottom: 10px;
}

.direct-chat-messages,
.direct-chat-contacts {
  @include transition(transform .5s ease-in-out);
}

.direct-chat-text {
  @if $enable-rounded {
    @include border-radius($border-radius-lg);
  }

  position: relative;
  padding: 5px 10px;
  margin: 5px 0 0 50px;
  color: $lte-direct-chat-default-font-color;
  background-color: $lte-direct-chat-default-msg-bg;
  border: 1px solid $lte-direct-chat-default-msg-border-color;

  //Create the arrow
  &::after,
  &::before {
    position: absolute;
    top: 15px;
    right: 100%;
    width: 0;
    height: 0;
    pointer-events: none;
    content: " ";
    border: solid transparent;
    border-right-color: $lte-direct-chat-default-msg-border-color;
  }

  &::after {
    margin-top: -5px;
    border-width: 5px;
  }

  &::before {
    margin-top: -6px;
    border-width: 6px;
  }

  .end & {
    margin-right: 50px;
    margin-left: 0;

    &::after,
    &::before {
      right: auto;
      left: 100%;
      border-right-color: transparent;
      border-left-color: $lte-direct-chat-default-msg-border-color;
    }
  }
}

.direct-chat-img {
  @include border-radius(50%);
  float: left;
  width: 40px;
  height: 40px;

  .end & {
    float: right;
  }
}

.direct-chat-infos {
  display: block;
  margin-bottom: 2px;
  font-size: $font-size-sm;
}

.direct-chat-name {
  font-weight: 600;
}

.direct-chat-timestamp {
  color: rgba(var(--#{$prefix}body-color-rgb), .75);
}

//Direct chat contacts pane
.direct-chat-contacts-open {
  .direct-chat-contacts {
    transform: translate(0, 0);
  }
}

.direct-chat-contacts {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 250px;
  overflow: auto;
  color: var(--#{$prefix}body-bg);
  background-color: var(--#{$prefix}body-color);
  transform: translate(101%, 0);
}

.direct-chat-contacts-light {
  background-color: var(--#{$prefix}light-bg-subtle);

  .contacts-list-name {
    color: var(--#{$prefix}body-color);
  }

  .contacts-list-date {
    color: var(--#{$prefix}secondary-color);
  }

  .contacts-list-msg {
    color: var(--#{$prefix}secondary-color);
  }
}

//Contacts list -- for displaying contacts in direct chat contacts pane
.contacts-list {
  @include list-unstyled();

  > li {
    @include clearfix();
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid rgba($black, .2);

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.contacts-list-img {
  @include border-radius(50%);
  float: left;
  width: 40px;
}

.contacts-list-info {
  margin-left: 45px;
  color: var(--#{$prefix}body-bg);
}

.contacts-list-name,
.contacts-list-status {
  display: block;
}

.contacts-list-name {
  font-weight: 600;
}

.contacts-list-status {
  font-size: $font-size-sm;
}

.contacts-list-date {
  font-weight: 400;
  color: var(--#{$prefix}secondary-bg);
}

.contacts-list-msg {
  color: var(--#{$prefix}secondary-bg);
}

.end > .direct-chat-text {
  color: var(--#{$lte-prefix}direct-chat-color);
  background-color: var(--#{$lte-prefix}direct-chat-bg);
  border-color: var(--#{$lte-prefix}direct-chat-bg);

  &::after,
  &::before {
    border-left-color: var(--#{$lte-prefix}direct-chat-bg);
  }
}

// Color variants
@each $name, $color in $theme-colors {
  .direct-chat-#{$name} {
    --#{$lte-prefix}direct-chat-color: #{color-contrast($color)};
    --#{$lte-prefix}direct-chat-bg: #{$color};
  }
}
